import React from 'react';
import './styles.css';

type WaveComponentProps = {
  reverse?: boolean;
};

const WaveComponent: React.FC<WaveComponentProps> = ({
  reverse,
}: WaveComponentProps) => (
  <>
    <div
      className={`wave-container ${reverse ? 'wave-container-reverse' : ''}`}
    >
      <svg viewBox="0 0 2560 211" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 0 95 C 161.5 95 161.5 147 323 147 L 323 147 L 323 0 L 0 0 Z"
          strokeWidth="0"
        />{' '}
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 322 147 C 475 147 475 93 628 93 L 628 93 L 628 0 L 322 0 Z"
          strokeWidth="0"
        />{' '}
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 627 93 C 773.5 93 773.5 151 920 151 L 920 151 L 920 0 L 627 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 919 151 C 1084.5 151 1084.5 89 1250 89 L 1250 89 L 1250 0 L 919 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 1249 89 C 1364.5 89 1364.5 145 1480 145 L 1480 145 L 1480 0 L 1249 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 1479 145 C 1597 145 1597 100 1715 100 L 1715 100 L 1715 0 L 1479 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 1714 100 C 1842.5 100 1842.5 146 1971 146 L 1971 146 L 1971 0 L 1714 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 1970 146 C 2081 146 2081 94 2192 94 L 2192 94 L 2192 0 L 1970 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 2191 94 C 2287 94 2287 143 2383 143 L 2383 143 L 2383 0 L 2191 0 Z"
          strokeWidth="0"
        />
        <path
          fill="rgba(var(--bs-pink-rgb))"
          fillOpacity="1"
          d="M 2382 143 C 2471 143 2471 98 2560 98 L 2560 98 L 2560 0 L 2382 0 Z"
          strokeWidth="0"
        />
      </svg>
    </div>
  </>
);

WaveComponent.defaultProps = {
  reverse: false,
};

export default WaveComponent;
