import React from 'react';
import ItemLinkTreeComponent from './Components/ItemLinkTree';
import './styles.css';

const LinkTree: React.FC = () => (
  <>
    <div className="container-linktree">
      <div className="container">
        <div className="col-xs-12">
          <div className="text-center">
            <ItemLinkTreeComponent
              icon={['fab', 'instagram']}
              label="Espaço IDEM no instagram"
              link="https://www.instagram.com/espaco.idem/"
            />
            <ItemLinkTreeComponent
              icon={['fab', 'google']}
              label="e-mail Espaço Item"
              link="mailto:espaco.idem.oficial@gmail.com"
              target={false}
            />
            <ItemLinkTreeComponent
              icon={['fab', 'instagram']}
              label="Instagram Rosângela Lopes"
              link="https://www.instagram.com/rosangela.flopes/"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default LinkTree;
