import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
  }

  body {
    background: var(--theme-background-color);
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px roboto, sans-serif;
    color: var(--black);
  }

  #root {
    margin: 0 auto;
  }

`;
