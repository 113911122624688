import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ItemLinkTreeWrapper } from './styles';

type ItemLinkTreeProps = {
  label: string;
  link: string;
  icon?: IconProp;
  target?: boolean;
};

const ItemLinkTreeComponent: React.FC<ItemLinkTreeProps> = ({
  label,
  link,
  icon,
  target,
}: ItemLinkTreeProps) => (
  <ItemLinkTreeWrapper>
    <a
      href={link}
      target={target ? '_blank' : ''}
      rel="noreferrer"
      className="btn btn-outline-dark fw-bold"
    >
      {!icon ? (
        ''
      ) : (
        <span className="p-1">
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      <span>{label}</span>
    </a>
  </ItemLinkTreeWrapper>
);

ItemLinkTreeComponent.defaultProps = {
  icon: undefined,
  target: true,
};

export default ItemLinkTreeComponent;
