import styled from 'styled-components';

export const ItemLinkTreeWrapper = styled.div`
  padding: 0.5rem;

  width: 100%;
  & a {
    color: var(--bs-gray-800) !important;
    width: 100%;
    &:hover {
      color: var(--bs-gray-100) !important;
    }
  }
`;
