import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter } from 'react-router-dom';
import { Navigation } from './pages/Navigation';
import Routes from './routes';
import GlobalStyle from './styles/global';

library.add(fab);

const App: React.FC = () => (
  <>
    <Navigation />
    <div className="top-3">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <GlobalStyle />
    </div>
  </>
);

export default App;
