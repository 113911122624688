import React from 'react';
import WaveComponent from '../Components/Wave';
import Profissional from '../Profissional';
import SectionServicos from '../Servicos/iindex';
import { Section, SectionPink } from './styles';

const LandingPage: React.FC = () => {
  return (
    <>
      <SectionPink id="home">
        <div className="container p-5">
          <div className="row">
            <div className="col-lg-6">
              <p className="fs-1 fw-bold text-center">
                Bem-vindo(a) ao no site{' '}
                <span className="fw-bold ei-green ">Espaço Idem</span>
                😃🤩
              </p>
              <p className="text-justify lh-base ">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&apos;s standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <img
                src="images/planofundo.png"
                className="img-thumbnail shadow-sm"
                alt="..."
              />
            </div>
          </div>
        </div>
      </SectionPink>
      <WaveComponent />
      <Section id="services">
        <SectionServicos />
      </Section>
      <SectionPink id="profissional">
        <WaveComponent reverse />
        <Profissional />
        <WaveComponent />
      </SectionPink>
      <Section id="space">
        <SectionServicos />
      </Section>
    </>
  );
};

export default LandingPage;
