import React from 'react';

type HorizontalCardProps = {
  title: string;
  alt: string;
  srcImage: string;
  children: React.ReactNode;
};

const HorizontalCardComponent: React.FC<HorizontalCardProps> = ({
  title,
  alt,
  srcImage,
  children,
}: HorizontalCardProps) => (
  <>
    <div className="card mb-3 shadow-sm">
      <div className="row g-0">
        <div className="col-md-4">
          <img src={srcImage} className="img-fluid rounded-start" alt={alt} />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{children}</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default HorizontalCardComponent;
