import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LandingPage from '../pages/LandingPage';
import LinkTree from '../pages/LinkTree';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={LandingPage}>
      <Redirect to="/linktree" push />
    </Route>
    <Route path="/linktree" component={LinkTree} />
  </Switch>
);

export default Routes;
