import React from 'react';

const Profissional: React.FC = () => (
  <>
    <div className="container">
      <h1 className="pt-3 mt-3 pb-3 mb-3">Profissional👩‍🏫📚</h1>
      <div className="row">
        <div className="col-lg-12 d-grid justify-content-center p-0">
          <div className="h-100 mx-auto p-0">
            <img
              src="images/jpg/profissional.jpg"
              className="img-thumbnail circle shadow-sm"
              alt="Foto da profissional Rosângela Lopes"
            />
          </div>
          <p className="text-center fw-bold rounded-pill border border-white shadow-sm p-1 mt-2">
            <span>Rosângela Lopes</span>
          </p>
          <span className="text-center fw-bold fs-6">SBNPp-7154</span>
          <small className="fs-7 text-muted text-center">
            Registro da Associação de Neuropsicopedagogia da&nbsp;
            <a
              href="https://www.sbnpp.org.br/associados"
              target="_blank"
              rel="noreferrer"
              className="no-underline"
            >
              Sociedade Brasileira de Neuropsicopedagogia
            </a>
          </small>
        </div>
        <div className="col-lg-12 d-grid justify-content-center">
          <div className="mx-auto mt-3">
            <p className="text-justify">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&apos;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p className="text-justify">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&apos;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p className="text-justify">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry&apos;s standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Profissional;
