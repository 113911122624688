import React from 'react';
import HorizontalCardComponent from '../Components/HorizontalCard';

const SectionServicos: React.FC = () => (
  <>
    <div className="container">
      <h1 className="pt-3 mt-3 pb-3 mb-3">Serviços📚🎯📝</h1>
      <div className="row">
        <div className="col-lg-6">
          <HorizontalCardComponent
            title="sfd"
            alt="alt"
            srcImage="https://s3.us-east-1.amazonaws.com/doctoralia.com.mx/doctor/f1f7c0/f1f7c02ac33038233edb42332ba54be1_large.jpg"
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&apos;s standard dummy
            text ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book. It has survived
            not only five centuries, but also the leap into electronic
            typesetting, remaining
          </HorizontalCardComponent>
        </div>
        <div className="col-lg-6">
          <HorizontalCardComponent
            title="sfd"
            alt="alt"
            srcImage="https://pbs.twimg.com/profile_images/1080898445945262080/FsoWMBLR_400x400.jpg"
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&apos;s standard dummy
            text ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book. It has survived
            not only five centuries, but also the leap into electronic
            typesetting, remaining
          </HorizontalCardComponent>
        </div>
        <div className="col-lg-6">
          <HorizontalCardComponent
            title="sfd"
            alt="alt"
            srcImage="images/jpg/familia.jpg"
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&apos;s standard dummy
            text ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book. It has survived
            not only five centuries, but also the leap into electronic
            typesetting, remaining
          </HorizontalCardComponent>
        </div>
        <div className="col-lg-6">
          <HorizontalCardComponent
            title="sfd"
            alt="alt"
            srcImage="https://s3.us-east-1.amazonaws.com/doctoralia.com.mx/doctor/f1f7c0/f1f7c02ac33038233edb42332ba54be1_large.jpg"
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&apos;s standard dummy
            text ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book. It has survived
            not only five centuries, but also the leap into electronic
            typesetting, remaining
          </HorizontalCardComponent>
        </div>
      </div>
    </div>
  </>
);

export default SectionServicos;
